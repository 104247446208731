import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../servicios/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class GuardianGuard implements CanLoad {

  constructor( private userService: UsuarioService) {}
  canLoad( ): Observable<boolean> | Promise <boolean> | boolean {
    return this.userService.validateToken();
  }
}