import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GuardianGuard } from "./guardianes/guardian.guard";
import { MainLayoutComponent } from "./shared/layouts/main-layout/main-layout.component";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./paginas/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: "inicio",
        loadChildren: () =>
          import("./paginas/inicio/inicio.module").then(
            (m) => m.InicioPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "plantilla",
        loadChildren: () =>
          import("./paginas/plantilla/plantilla.module").then(
            (m) => m.PlantillaPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "administradores",
        loadChildren: () =>
          import("./paginas/administradores/administradores.module").then(
            (m) => m.AdministradoresPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "estudiantes",
        loadChildren: () =>
          import("./paginas/estudiantes/estudiantes.module").then(
            (m) => m.EstudiantesPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "profesores",
        loadChildren: () =>
          import("./paginas/profesores/profesores.module").then(
            (m) => m.ProfesoresPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "categorias",
        loadChildren: () =>
          import("./paginas/categorias/categorias.module").then(
            (m) => m.CategoriasPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "subcategorias",
        loadChildren: () =>
          import("./paginas/subcategorias/subcategorias.module").then(
            (m) => m.SubcategoriasPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "cursos",
        loadChildren: () =>
          import("./paginas/cursos/cursos.module").then(
            (m) => m.CursosPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "banner",
        loadChildren: () =>
          import("./paginas/banner/banner.module").then(
            (m) => m.BannerPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "cupones",
        loadChildren: () =>
          import("./paginas/cupones/cupones.module").then(
            (m) => m.CuponesPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "ventas",
        loadChildren: () =>
          import("./paginas/ventas/ventas.module").then(
            (m) => m.VentasPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: "servicios",
        loadChildren: () =>
          import("./paginas/servicios/servicios.module").then(
            (m) => m.ServiciosPageModule
          ),
        canLoad: [GuardianGuard],
      },
      {
        path: '**',
        redirectTo: 'inicio',
      }
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabled',  scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
