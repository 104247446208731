import { Injectable } from '@angular/core';
import { Administrador } from '../interfaces/interfaz';
import { NavController } from '@ionic/angular';
import { UiService } from './ui.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  urlFinal: string;
  token: string = null;
  usuario: Administrador = {};  
  public administrador: Administrador = null;
  storage: globalThis.Storage = localStorage;
  
  constructor(private http: HttpClient, private navCtrl: NavController, private uiService: UiService) {
    this.loadToken();    
  }
  async AdminLog(correo: string, contrasena: string) {
    await this.uiService.presentLoading('Cargando...');
    const data = { correo: correo, contrasena: contrasena };
    this.urlFinal = apiUrl + 'administrador-login';
    return new Promise(resolve => {
      this.http.post<any>(this.urlFinal, data).subscribe(
        resp => {
          this.uiService.paraLoading();
          if (resp.respuesta) {
            this.saveToken(resp.administrador.acc_token);
            this.usuario = resp.administrador;
            this.token = resp.administrador.acc_token;
            resolve(true);
          } else {
            this.token = null;
            this.storage.clear();
            resolve(false);
          }
        });
    });
  }
  async saveToken(token: string) {
    this.token = token;
    await this.storage.setItem('token', this.token);
  }
  loadToken() {
    this.token = this.storage.getItem('token') || null;
  }
  async validateToken(): Promise<boolean> {
    this.loadToken();
    if (!this.token) {
      this.navCtrl.navigateRoot('login');
      return Promise.resolve(false);
    }
    this.urlFinal = apiUrl + 'administrador-token';
    const headers = new HttpHeaders({
      'x-token': this.token,      
    });    
    return new Promise<boolean>(resolve => {
      this.http.get<any>(this.urlFinal, { headers }).subscribe(resp => {
        if (resp.respuesta) {
          this.usuario = resp.administrador;
          resolve(true);
        } else {
          this.navCtrl.navigateRoot('login');
          resolve(false);
        }
      });
    });
  }
  getUserToken() {
    if (!this.usuario.id) {
      this.validateToken();
    }
    return { ...this.usuario };
  }
  salir() {
    this.token = null;
    this.usuario = {};
    this.storage.clear();
    this.validateToken();
  }
}