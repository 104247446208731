import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/servicios/usuario.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pop-menu-admin',
  templateUrl: './pop-menu-admin.component.html',
  styleUrls: ['./pop-menu-admin.component.scss'],
})
export class PopMenuAdminComponent implements OnInit {
  
  constructor(private sUsuario: UsuarioService, private pop: PopoverController) { }

  ngOnInit() {}

  salir() {    
    this.pop.dismiss();
    this.sUsuario.salir();
  }

}
