import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UsuarioService } from 'src/app/servicios/usuario.service';
import { PopMenuAdminComponent } from '../../../componentes/pop-menu-admin/pop-menu-admin.component';
import { PlantillaService } from 'src/app/servicios/plantilla.service';
import { Plantilla } from 'src/app/interfaces/interfaz';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() titulo: string;  
  plantilla: Plantilla = {};
  constructor(private pop: PopoverController, public sUsuario: UsuarioService, public sPlantilla: PlantillaService) { }

  async ngOnInit() { await this.sUsuario.loadToken(); this.cargarPlantilla();  }

  async mostrarMenuAdmin( ev: any ){    
      const popover = await this.pop.create({
        component: PopMenuAdminComponent,        
        event: ev,
        translucent: false,
        showBackdrop: false      
      });
      return await popover.present();        
  }
  async cargarPlantilla(){
    this.sPlantilla.traerPlantilla().subscribe( response =>{
      if(response.respuesta){
        this.plantilla = response.plantilla;
      }
    })
  }
}