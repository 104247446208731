import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from './usuario.service';
import { Plantilla, ResponsePlantilla } from '../interfaces/interfaz';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class PlantillaService {

  public datosPlantilla: Plantilla = {};
  urlFinal: string;
  constructor(private http: HttpClient, private sUsuario: UsuarioService) {
    //this.traerPlantilla();
  }
  verInico(){
    const headers = new HttpHeaders({
      'x-token': this.sUsuario.token
    });
    this.urlFinal = apiUrl + 'inicio-administrador';
    return this.http.get<any>(this.urlFinal, {headers} );
  }
  verEstadisticasInico(){
    const headers = new HttpHeaders({
      'x-token': this.sUsuario.token
    });
    this.urlFinal = apiUrl + 'estadisticas-inicio-admin';
    return this.http.get<any>(this.urlFinal, {headers} );
  }
  traerPlantillaFuera() {   
    this.urlFinal = apiUrl + 'plantilla-consultar-usuario';
    return this.http.get<any>(this.urlFinal);
  };
  /*CODIGO PARA TRAER DATOS BASICOS DE TIENDA*/
  traerPlantilla() {
    const headers = new HttpHeaders({
      'x-token': this.sUsuario.token
    });
    this.urlFinal = apiUrl + 'plantilla-consultar';
    return this.http.get<ResponsePlantilla>(this.urlFinal, {headers});
  };
  getMenu() {
    const headers = new HttpHeaders({
      'x-token': this.sUsuario.token
    });
    this.urlFinal = apiUrl + 'administrador-listar-menu';
    return this.http.get<any>(this.urlFinal, {headers} );
  }
  actualizarPlantilla(plantilla: Plantilla, logo: File) {
    const data = this.setear( plantilla, logo);
    const headers = new HttpHeaders({
      'x-token': this.sUsuario.token
    });
    this.urlFinal = apiUrl + 'plantilla-editar';
    return this.http.post<any>(this.urlFinal, data , { headers });
  }  
  setear( plantilla: Plantilla, logo: File){
    const data = new FormData();
    data.append('account_id', plantilla.account_id );
    data.append('api_key', plantilla.api_key );
    data.append('merchan_id', plantilla.merchan_id );
    data.append('api_login', plantilla.api_login );
    data.append('ciudad', plantilla.ciudad );
    data.append('direccion', plantilla.direccion );
    data.append('email', plantilla.email );
    data.append('facebook', plantilla.facebook );
    data.append('google_analitics', plantilla.google_analitics );
    data.append('instagram', plantilla.instagram );
    data.append('iva', plantilla.iva );    
    data.append('mapa', plantilla.mapa );    
    data.append('nombre', plantilla.nombre );
    data.append('descripcion', plantilla.descripcion );
    data.append('pixel_facebook', plantilla.pixel_facebook );
    data.append('telefono', plantilla.telefono );
    data.append('twitter', plantilla.twitter );
    data.append('whatsapp', plantilla.whatsapp );
    if(logo)data.append('logo', logo, logo.name);    
    return data;
  }
  normalizar = (function () {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
      to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapa = {};
    for (var i = 0, j = from.length; i < j; i++)
      mapa[from.charAt(i)] = to.charAt(i);
    return function (str) {
      var cadenaFinal = [];
      for (var i = 0, j = str.length; i < j; i++) {
        var c = str.charAt(i);
        if (mapa.hasOwnProperty(str.charAt(i))) {
          cadenaFinal.push(mapa[c]);
        } else {
          cadenaFinal.push(c);
        }
      }
      return cadenaFinal.join('').replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase();
    };
  })();
  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
}