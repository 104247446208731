import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlantillaService } from 'src/app/servicios/plantilla.service';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss'],
})
export class MenuLateralComponent implements OnInit {
  menu: any[] = [];
  activePath: string = '';
  constructor(private sPlantilla: PlantillaService, private router: Router) { 
    this.activePath = this.router.url;
  }

  ngOnInit() {
    this.cargarMenu();
  }

  cargarMenu(){
    this.sPlantilla.getMenu().subscribe( response => {
      if(response.respuesta){
        this.menu = response.menu;              
      }
    })
  }
  navegar( route: string ): void{
    this.activePath = route;
    this.router.navigateByUrl(route);
  }
}