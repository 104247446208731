import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private toastController: ToastController, public loadController: LoadingController) { }

  async toastInformativo( message: string, color: string) {
    const toast = await this.toastController.create({
      message,
      duration: 4000,
      color,
      position: "top",
      cssClass: 'ion-text-center'
    });
    toast.present();
  }
  async presentLoading(message: string ) {
    const loading = await this.loadController.create({
      message,
    });
    await loading.present();
  }
  paraLoading(){
    this.loadController.dismiss();
  }
}
