import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ContainersModule } from './shared/container/containers.module';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppComponent, MainLayoutComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule, 
    IonicModule.forRoot(),
    HttpClientModule,
    ContainersModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
